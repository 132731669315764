import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES, RESERVATIONS_SETTINGS_PAGE } from '../../routing/routeConfiguration';
import { LinkTabNavHorizontal } from '../../components';

import css from './UserNav.module.css';
import { useSelector } from 'react-redux';
import { ensureUser } from '../../util/data';

const UserNav = props => {
  const { className, rootClassName, currentPage } = props;
  const currentUser = ensureUser(useSelector(state => state?.user?.currentUser))
  const classes = classNames(rootClassName || css.root, className);

  const tabs = [
    {
      text: <FormattedMessage id="UserNav.yourListings" />,
      selected: currentPage === 'ManageListingsPage',
      linkProps: {
        name: 'ManageListingsPage',
      },
    },
    {
      text: <FormattedMessage id="UserNav.favouriteListings"/>,
      selected: currentPage === 'FavouriteListingsPage',
      linkProps: {
        name: 'FavouriteListingsPage'
      }
    },
    // {
    //   text: <FormattedMessage id="UserNav.ReservaAntiguesPage"/>,
    //   selected: currentPage === 'ReservaAntiguesPage',
    //   linkProps: {
    //     name: 'ReservaAntiguesPage'
    //   }
    // },
    {
      text: <FormattedMessage id="UserNav.profileSettings" />,
      selected: currentPage === 'ProfileSettingsPage',
      disabled: false,
      linkProps: {
        name: 'ProfileSettingsPage',
      },
    },
    {
      text: <FormattedMessage id="UserNav.accountSettings" />,
      selected: ACCOUNT_SETTINGS_PAGES.includes(currentPage),
      disabled: false,
      linkProps: {
        name: 'ContactDetailsPage',
      },
    },
    {
      text: <FormattedMessage id="UserNav.reservationList" />,
      selected: RESERVATIONS_SETTINGS_PAGE.includes(currentPage),
      disabled: false,
      linkProps: {
        name: 'HoldListingsPage',
      },
    },
  ];

  return (
    <LinkTabNavHorizontal className={classes} tabRootClassName={css.tab} currentUser={currentUser} tabs={tabs} skin="dark" />
  );
};

UserNav.defaultProps = {
  className: null,
  rootClassName: null,
};

const { string } = PropTypes;

UserNav.propTypes = {
  className: string,
  rootClassName: string,
  currentPage: string.isRequired,
};

export default UserNav;
