import React from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { compose } from "redux";
import { Page } from "../../components";
import { isScrollingDisabled } from "../../ducks/ui.duck";
import TopbarContainer from "../TopbarContainer/TopbarContainer";

import css from './CostCalculation.module.css';
import FooterComponent from "../FooterContainer/FooterContainer";

export const CostCalculationPageComponent = props => {
    const {
        scrollingDisabled,
        intl
    } = props;

    return(
        <Page
            title={"Cost calculator page"}
            scrollingDisabled={scrollingDisabled}
        >
            <TopbarContainer
                currentPage={"Cost calculator"}
                desktopClassName={css.desktopTopbar}
                mobileClassName={css.mobileTopbar}
            />
            <iframe src="https://private.cocopool.es/reservas/calculadora.php" className={css.iframe}></iframe>
            <FooterComponent/>
        </Page>
    )
};

const mapStateToProps = state =>{
    console.log(state.user, "dsh");
    return{
        scrollingDisabled: isScrollingDisabled(state),
    };
};

const mapDispatchToProps = dispatch =>({});

const CostCalculationPage = compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    injectIntl
)(CostCalculationPageComponent);

export default CostCalculationPage;