import { types as sdkTypes } from '../util/sdkLoader';

const { LatLng, LatLngBounds } = sdkTypes;

// An array of locations to show in the LocationAutocompleteInput when
// the input is in focus but the user hasn't typed in any search yet.
//
// Each item in the array should be an object with a unique `id` (String) and a
// `predictionPlace` (util.types.place) properties.
//
// NOTE: these are highly recommended, since they
//       1) help customers to find relevant locations, and
//       2) reduce the cost of using map providers geocoding API
const defaultLocations = [
  {
    id: "default-barcelona",
    predictionPlace: {
      address: "Barcelona, Barcelona, Spain",
      bounds: new LatLngBounds(new LatLng(41.7422201108286, 2.6563365077852117), new LatLng(41.0235678891714, 1.6985274922147884)),
      origin: new LatLng(41.382894, 2.177432)
    },
  },
  {
    id: "default-madrid",
    predictionPlace: {
      address: "Madrid, Madrid, Spain",
      bounds: new LatLngBounds(new LatLng(40.7760311108286, -3.2316234072311727), new LatLng(40.0573788891714, -4.175542592768827)),
      origin: new LatLng(40.416705, -3.703583)
    }
  },
  {
    id: "default-sevilla",
    predictionPlace: {
      address: "Seville, Seville, Spain",
      bounds: new LatLngBounds(new LatLng(37.747956110828596, -5.543093025605916), new LatLng(37.0293038891714, -6.447586974394083)),
      origin: new LatLng(37.38863, -5.99534)
    }
  },
  {
    id: "default-valencia",
    predictionPlace: {
      address: "Valencia, Valencia, Spain",
      bounds: new LatLngBounds(new LatLng(39.8290331108286, 0.08913697866603537), new LatLng(39.1103808891714, -0.8418069786660354)),
      origin: new LatLng(39.469707, -0.376335)
    }
  },
  {
    id: "default-tarragona",
    predictionPlace: {
      address: "Tarragona, Tarragona, Spain",
      bounds: new LatLngBounds(new LatLng(41.476562110828596, 1.7315671318054506), new LatLng(40.7579098891714, 0.7776448681945496)),
      origin: new LatLng(41.117236, 1.254606)
    }
  },
  {
    id: "default-girona",
    predictionPlace: {
      address: "Girona, Girona, Spain",
      bounds: new LatLngBounds(new LatLng(42.3407327108286, 3.30466565936716), new LatLng(41.6220804891714, 2.33790614063284)),
      origin: new LatLng(41.9814066, 2.8212859)
    }
  },
  {
    id: "default-toledo",
    predictionPlace: {
      address: "Toledo, Toledo, Spain",
      bounds: new LatLngBounds(new LatLng(40.2160665108286, -3.5564988772645565), new LatLng(39.4974142891714, -4.492671522735443)),
      origin: new LatLng(39.8567404, -4.0245852)
    }
  }
];
export default defaultLocations;
