import React, { useEffect, useLayoutEffect, useState } from 'react';
import { compose } from 'redux';
import { string, func, bool } from 'prop-types';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import classNames from 'classnames';

import { useConfiguration } from '../../context/configurationContext';
import { AspectRatioWrapper, NamedLink, ResponsiveImage } from '../../components';

// Utils
import { displayPrice } from '../../util/configHelpers';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { richText } from '../../util/richText';
import { types as sdkTypes } from "../../util/sdkLoader";
import { propTypes } from '../../util/types';
import { lazyLoadWithDimensions } from '../../util/uiHelpers';
import { createSlug } from '../../util/urlHelpers';

import { isBookingProcessAlias } from '../../transactions/transaction';
import IconCard from '../SavedCardDetails/IconCard/IconCard';

import css from './ListingCard.module.css';
import { createResourceLocatorString } from '../../util/routes';
import Slider from 'react-slick';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';


const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};


function SampleNextArrow(props) {
  const { className, style, onClick } = props;

  return (
    <div
      className={className}
      style={{ ...style, display: 'block', userSelect: "none" }}
      onClick={(e)=>{
        e.preventDefault();
        e.stopPropagation();
        onClick();
      }}
    >
      <svg width="30px" height="30px" viewBox="0 0 0.75 0.75" xmlns="http://www.w3.org/2000/svg"><path fill="#fff" fillRule="evenodd" d="m0.264 0.081 0.272 0.272a0.025 0.025 0 0 1 0.008 0.018 0.026 0.026 0 0 1 -0.008 0.019c-0.098 0.096 -0.193 0.188 -0.284 0.278 -0.005 0.004 -0.023 0.015 -0.038 -0.001 -0.014 -0.016 -0.006 -0.03 0 -0.036l0.265 -0.259 -0.253 -0.253c-0.009 -0.013 -0.008 -0.024 0.002 -0.035 0.011 -0.011 0.023 -0.011 0.036 -0.002Z" /></svg>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;

  return (
    <div
      className={className}
      style={{ ...style, display: 'block', userSelect: "none" }}
      onClick={(e)=>{
        e.preventDefault();
        e.stopPropagation();
        onClick();
      }}
    >
      <svg width="30px" height="30px" viewBox="0 0 0.75 0.75" xmlns="http://www.w3.org/2000/svg"><path fill="#fff" fillRule="evenodd" d="M0.271 0.371c0.086 -0.087 0.171 -0.171 0.253 -0.253a0.024 0.024 0 0 0 0 -0.034c-0.012 -0.013 -0.03 -0.012 -0.039 -0.003 -0.085 0.086 -0.175 0.175 -0.268 0.268 -0.007 0.006 -0.011 0.013 -0.011 0.022 0 0.008 0.004 0.016 0.011 0.022l0.281 0.274a0.028 0.028 0 0 0 0.039 -0.001c0.013 -0.013 0.008 -0.027 0.002 -0.033a127.79 127.79 0 0 1 -0.269 -0.262Z" /></svg>
    </div>
  );
}

const LazyImage = lazyLoadWithDimensions(ResponsiveImage, { loadAfterInitialRendering: 3000 });

const PriceMaybe = props => {
  const { price, publicData, config, intl } = props;
  const { listingType } = publicData || {};
  const validListingTypes = config.listing.listingTypes;
  const foundListingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
  const showPrice = displayPrice(foundListingTypeConfig);
  if (!showPrice && price) {
    return null;
  };

  const isBookable = isBookingProcessAlias(publicData?.transactionProcessAlias);
  const { formattedPrice, priceTitle } = priceData(price, config.currency, intl);
  return (
    <div className={css.price}>
      {isBookable ? (
        <div className={css.priceValue} title={priceTitle}>
          <FormattedMessage id={"ListingCard.priceLabel"} values={{ price: formattedPrice }} /> <span>/h</span>
        </div>
      ) : null}
    </div>
  );
};

export const ListingCardComponent = props => {
  const config = useConfiguration();
  const routes = useRouteConfiguration();
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    setActiveListing,
    showAuthorInfo,
    currentUser,
    listingId,
    isAuthenticated,
    onupdateProfile,
    history,
    reviews,
    onFetchReviews
  } = props;

  const [inProgress, setinProgress] = useState(false);

  const { Money } = sdkTypes;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const authorAvailable = currentListing && currentListing.author;
  const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
  const isOwnListing =
    userAndListingAuthorAvailable && currentListing.author?.id?.uuid === currentUser?.id?.uuid;
  const { title = '', price = new Money(100, "EUR"), publicData } = currentListing.attributes || {};
  const { location, maxGuest, tag=[] } = publicData || {};
  const { favouriteListings = [] } = currentUser?.attributes?.profile?.privateData || {};
  const isFavourite = favouriteListings?.includes(listingId);
  const slug = createSlug(title);
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;
  const listingLocation = location && location?.address && location?.address?.split(", ")[1];

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;

  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

  const setActivePropsMaybe = setActiveListing
    ? {
      onMouseEnter: () => setActiveListing(currentListing.id),
      onMouseLeave: () => setActiveListing(null),
    }
    : null;

  const settings = {
    speed: 500,
    dots: false,
    arrows: true,
    slidesToShow: 1,
    infinite: true,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const handleShareAddtoFavourite = async (listingId, favourites) => {
    if (isAuthenticated) {
      setinProgress(true);
      const newArr = [...favourites];
      if (isFavourite) {
        const indexOfId = newArr?.indexOf(listingId);
        newArr.splice(indexOfId, 1);
      }
      else {
        newArr.push(listingId);
      }
      const profile = {
        privateData: {
          favouriteListings: newArr
        }
      };
      await onupdateProfile(profile).then(() => {
        setinProgress(false);
      })
    }
    else {
      history.push('/login');
    }
  };

  const handleRedirectLink = ()=>{
    history.push(createResourceLocatorString('ListingPage', routes, { id, slug }, {}));
  };

  return (
    <div onClick={() => handleRedirectLink()} className={classes}>
      {currentListing.images && currentListing.images.length > 1 ?
        <div className={css.sliderWrapper}>
          <Slider {...settings}>
            {currentListing.images.map((st, index) => {
              return (
                <AspectRatioWrapper
                  width={aspectWidth}
                  height={aspectHeight}
                  key={index}
                  className={css.aspectRatioWrapper}
                >
                  <ResponsiveImage
                    rootClassName={css.rootForImage}
                    alt={title}
                    image={st}
                    variants={Object.keys(st?.attributes?.variants).filter(k => k.startsWith(variantPrefix))}
                    sizes={renderSizes}
                  />
                  <div className={css.priceBox}>
                    <PriceMaybe price={price} publicData={publicData} config={config} intl={intl} />
                    {Array.isArray(tag) && tag?.length && <div className={css.ListingCard_tag} style={{backgroundColor: tag[0]?.colour }}>{tag[0]?.text}</div>}
                  </div>
                </AspectRatioWrapper>
              )
            })}
          </Slider>
        </div> :
        <AspectRatioWrapper width={aspectWidth} height={aspectHeight} className={css.aspectRatioWrapperSingle}>
          <ResponsiveImage
            rootClassName={css.rootForImage}
            alt={title}
            image={firstImage}
            variants={variants}
            sizes={renderSizes}
            
          />
          <div className={css.priceBox}>
            <PriceMaybe price={price} publicData={publicData} config={config} intl={intl} />
          </div>
        </AspectRatioWrapper>
      }
      <div className={css.info}>
        <div className={css.title}>
          {richText(title, {
            longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
            longWordClass: css.longWord,
          })}
        </div>
        <div className={css.cityName}>
          <span className={css.locationName}>{listingLocation}</span>
        </div>
        <div className={css.cardBottonBox}>
          <div className={css.rowReview}>
            <div className={css.peopleLocation}>
              <div className={css.iconHeading}>
                <span className={css.locationIcon} >
                  <IconCard brand="user" />
                </span>
                <span className={css.locationName}>{maxGuest == "51_above" ? "+51" : maxGuest}</span>
              </div>
            </div>
            <div className={css.reviewsBox}>
              <span className={css.starIcon}>
                {
                  reviews > 0
                    ?
                    <span className={css.fillStar}><IconCard brand="cardstar" /></span>
                    :
                    <span className={css.blankStar}><IconCard brand="blankstar" /></span>
                }
              </span>
              <span>({ reviews })</span>
              {/* <span>({ customerReviews })</span> */}
            </div>
          </div>
          <div
            className={css.favIcon}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              !inProgress && handleShareAddtoFavourite(listingId, favouriteListings)
            }}>
            {!isOwnListing ? <div className={css.keepButton}>
              {isFavourite ?
                <div>
                  <span><IconCard brand="like" /></span>
                </div> :
                <div>
                  <span className={css.dislike}><IconCard brand="dislike" /></span>
                </div>
              }
            </div> : null}
          </div>
        </div>
      </div>
      {/* </SnackbarProvider> */}
    </div>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  showAuthorInfo: bool,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default compose(injectIntl, withRouter)(ListingCardComponent);
