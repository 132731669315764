export const FB_PIXEL_ID = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID;

export const pageview = () => {
  typeof window != "undefined" && window && window.fbq("track", "PageView");
};

// https://developers.facebook.com/docs/facebook-pixel/advanced/
export const event = (name, options = {}, type="") => {
  if(type == "custom"){
    typeof window != "undefined" && window && window.fbq("trackCustom", name, options);
  } else {
    typeof window != "undefined" && window && window.fbq("track", name, options);
  }
};